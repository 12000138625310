






















export default {
  name: 'ImagePreviewDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      default: ''
    }
  },
  methods: {
    closeDialog(): void {
      this.$emit('close');
    }
  }
};
